import 'select2';

const initSelect2 = () => {
  $('.js-searchable').select2({
    width: '100%',
    theme: "bootstrap"
  });
  $('.select2-selection').addClass('form-control')
}

export { initSelect2 };
