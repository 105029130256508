if (window.location.pathname === '/infos') {
  const form_title = document.querySelector('#form_title')
  form_title.scrollIntoView()
}

// fade out sur .alert.sc / flashes.erb
$(document).ready(function () {
  $('.alert').delay(2000).fadeOut();
});

// scrolldown
$(function () {
  $('a[href*="#"]').on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: $($(this).attr('href')).offset().top }, 500, 'linear');
  });
});
