require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import "bootstrap";
import "../components/julu";
import 'select2/dist/css/select2.css';
import { initSelect2 } from '../components/select2';
import { emailValidation } from '../components/email_validation'
import { copyText } from '../components/copy_text';

console.log("Connected BC")
emailValidation()
copyText()
initSelect2()
