const emailValidation = () => {
  const email = document.querySelector('#info_email')
  const email_validation = document.querySelector('#info_email_validation')
  const button = document.querySelector('#home_form_val .btn')

  if (button) {
    button.disabled = true
    button.classList.add('disabled')
  }

  document.addEventListener('keyup', () => {
    if (email && email_validation) {
      if (email.value === email_validation.value && email_validation.value !== '') {
        button.disabled = false
        button.classList.remove('disabled');
      } else {
        button.disabled = true
        button.classList.add('disabled')
      }
    }
  })
}

export { emailValidation }
