const copyText = () => {
  const areas = document.querySelectorAll(".copy-area")

  if (areas) {
    areas.forEach(area => {
      const button = area.querySelector('.admin-button')
      const text = area.querySelector('.text-to-copy')

      button.addEventListener('click', (event) => {
        text.select()
        text.setSelectionRange(0, 99999); /*For mobile devices*/

        document.execCommand("copy")
        navigator.clipboard.writeText(text.value)

        event.target.innerHTML = 'Copié !'
        event.target.classList.add('own-disabled')

        setTimeout(() => {
          event.target.innerHTML = 'Copier'
          event.target.classList.remove('own-disabled')
        }, 3000)
      })
    });
  }
}

export { copyText };
